<template>
  <div class="scroll growarea">
    <div>
      <div type="light" variant="light">
        <div>
          <b-nav-text>Filter By</b-nav-text>
          <div class="growarea__filterGrow">
            <b-button-group :vertical="windowWidth < 768">
              <b-button
                class="growarea__filterGrow__btnFilter"
                :style="
                  change_Tab_Id === 0
                    ? { 'background-color': '#abaeb0', color: '#f9fdff' }
                    : { 'background-color': '#f9fdff', color: '#abaeb0' }
                "
                @click="onSelectedTabChange(0)"
                ><i class="fas fa-wind"></i> Air</b-button
              >
              <b-button
                class="growarea__filterGrow__btnFilter"
                @click="onSelectedTabChange(1)"
                :style="
                  change_Tab_Id === 1
                    ? { 'background-color': '#abaeb0', color: '#f9fdff' }
                    : { 'background-color': '#f9fdff', color: '#abaeb0' }
                "
              >
                <i class="fas fa-water"></i> Water</b-button
              >
              <b-button
                class="growarea__filterGrow__btnFilter"
                @click="onSelectedTabChange(2)"
                :style="
                  change_Tab_Id === 2
                    ? { 'background-color': '#abaeb0', color: '#f9fdff' }
                    : { 'background-color': '#f9fdff', color: '#abaeb0' }
                "
              >
                <i class="fas fa-lightbulb"></i> Lights</b-button
              >
            </b-button-group>
            &nbsp;
            <b-button class="growarea__filterGrow__btnFilter">
              <i class="fas fa-exclamation-triangle"></i>
              Alert</b-button
            >
            &nbsp;
            <b-button-group :vertical="windowWidth < 768">
              <b-button
                class="growarea__filterGrow__btnFilter"
                :style="
                  dur == 3
                    ? { 'background-color': '#f9fdff', color: '#abaeb0' }
                    : { 'background-color': '#abaeb0', color: '#f9fdff' }
                "
                @click="onDurationChange(3)"
                >3HR</b-button
              >
              <b-button
                class="growarea__filterGrow__btnFilter"
                @click="onDurationChange(6)"
                :style="
                  dur == 6
                    ? { 'background-color': '#f9fdff', color: '#abaeb0' }
                    : { 'background-color': '#abaeb0', color: '#f9fdff' }
                "
              >
                6HR</b-button
              >
              <b-button
                class="growarea__filterGrow__btnFilter"
                @click="onDurationChange(12)"
                :style="
                  dur == 12
                    ? { 'background-color': '#f9fdff', color: '#abaeb0' }
                    : { 'background-color': '#abaeb0', color: '#f9fdff' }
                "
              >
                12HR</b-button
              >
              <b-button
                class="growarea__filterGrow__btnFilter"
                @click="onDurationChange(24)"
                :style="
                  dur == 24
                    ? { 'background-color': '#f9fdff', color: '#abaeb0' }
                    : { 'background-color': '#abaeb0', color: '#f9fdff' }
                "
              >
                24HR</b-button
              >
            </b-button-group>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="growarea__flex-container">
        <div v-for="(ga, idx) in growareas" :key="idx" class="growarea__cardstyle-1">
          <GrowAreaCard
            :message="change_Tab_Id"
            :divCounter="idx"
            :graphId="ga"
            :farm="farm"
            :duration="dur"
            class="box-shadow-1 growarea__cardstyle-1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import GrowAreaCard from "../components/GrowAreaCard.vue";

export default {
  name: "GrowAreas",
  components: {
    GrowAreaCard,
  },
  props: ["farm"],
  data: function () {
    return {
      windowWidth: window.innerWidth,
      change_Tab_Id: 1,
      dur: 6,
      growareas: [],
    };
  },
  watch: {
    farm: function () {
      this.growareas = [];
      this.getGrowareas();
    },
  },
  created() {
    window.addEventListener('resize', this.onResize);
    this.getGrowareas();
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    onDurationChange(newValue) {
      this.dur = newValue;
    },
    onSelectedTabChange(newValue) {
      this.change_Tab_Id = newValue;
    },
    getGrowareas() {
      axios
        .get(`/${this.farm}/zones`, { })
        .then((response) => {
          this.growareas = response.data;
          console.log(this.growareas);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style scoped>
.growarea {
  width: fit-content;
}

.growarea__filterGrow {
  margin-left: 20px;
}
.growarea__filterGrow__btnFilter {
  background-color: #abaeb0;
  border-color: #abaeb0;
  border-radius: 20px;
}

/* This code will do the magic. */
.growarea__flex-container {
  display: flex;
  flex-flow: row wrap;
}
/* This code is just to style the inner div elements. */
.growarea__flex-container > div {
  width: 400px;
  margin: 15px 15px;
}

.growarea__cardstyle-1 {
  border-radius: 10px;
}

@media only screen and (max-width: 426px) {
  .growarea__filterGrow {
    display: flex;
    justify-content: space-around;
 }
}
</style>
