var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll growarea"},[_c('div',[_c('div',{attrs:{"type":"light","variant":"light"}},[_c('div',[_c('b-nav-text',[_vm._v("Filter By")]),_c('div',{staticClass:"growarea__filterGrow"},[_c('b-button-group',{attrs:{"vertical":_vm.windowWidth < 768}},[_c('b-button',{staticClass:"growarea__filterGrow__btnFilter",style:(_vm.change_Tab_Id === 0
                  ? { 'background-color': '#abaeb0', color: '#f9fdff' }
                  : { 'background-color': '#f9fdff', color: '#abaeb0' }),on:{"click":function($event){return _vm.onSelectedTabChange(0)}}},[_c('i',{staticClass:"fas fa-wind"}),_vm._v(" Air")]),_c('b-button',{staticClass:"growarea__filterGrow__btnFilter",style:(_vm.change_Tab_Id === 1
                  ? { 'background-color': '#abaeb0', color: '#f9fdff' }
                  : { 'background-color': '#f9fdff', color: '#abaeb0' }),on:{"click":function($event){return _vm.onSelectedTabChange(1)}}},[_c('i',{staticClass:"fas fa-water"}),_vm._v(" Water")]),_c('b-button',{staticClass:"growarea__filterGrow__btnFilter",style:(_vm.change_Tab_Id === 2
                  ? { 'background-color': '#abaeb0', color: '#f9fdff' }
                  : { 'background-color': '#f9fdff', color: '#abaeb0' }),on:{"click":function($event){return _vm.onSelectedTabChange(2)}}},[_c('i',{staticClass:"fas fa-lightbulb"}),_vm._v(" Lights")])],1),_vm._v("   "),_c('b-button',{staticClass:"growarea__filterGrow__btnFilter"},[_c('i',{staticClass:"fas fa-exclamation-triangle"}),_vm._v(" Alert")]),_vm._v("   "),_c('b-button-group',{attrs:{"vertical":_vm.windowWidth < 768}},[_c('b-button',{staticClass:"growarea__filterGrow__btnFilter",style:(_vm.dur == 3
                  ? { 'background-color': '#f9fdff', color: '#abaeb0' }
                  : { 'background-color': '#abaeb0', color: '#f9fdff' }),on:{"click":function($event){return _vm.onDurationChange(3)}}},[_vm._v("3HR")]),_c('b-button',{staticClass:"growarea__filterGrow__btnFilter",style:(_vm.dur == 6
                  ? { 'background-color': '#f9fdff', color: '#abaeb0' }
                  : { 'background-color': '#abaeb0', color: '#f9fdff' }),on:{"click":function($event){return _vm.onDurationChange(6)}}},[_vm._v(" 6HR")]),_c('b-button',{staticClass:"growarea__filterGrow__btnFilter",style:(_vm.dur == 12
                  ? { 'background-color': '#f9fdff', color: '#abaeb0' }
                  : { 'background-color': '#abaeb0', color: '#f9fdff' }),on:{"click":function($event){return _vm.onDurationChange(12)}}},[_vm._v(" 12HR")]),_c('b-button',{staticClass:"growarea__filterGrow__btnFilter",style:(_vm.dur == 24
                  ? { 'background-color': '#f9fdff', color: '#abaeb0' }
                  : { 'background-color': '#abaeb0', color: '#f9fdff' }),on:{"click":function($event){return _vm.onDurationChange(24)}}},[_vm._v(" 24HR")])],1)],1)],1)])]),_c('div',[_c('div',{staticClass:"growarea__flex-container"},_vm._l((_vm.growareas),function(ga,idx){return _c('div',{key:idx,staticClass:"growarea__cardstyle-1"},[_c('GrowAreaCard',{staticClass:"box-shadow-1 growarea__cardstyle-1",attrs:{"message":_vm.change_Tab_Id,"divCounter":idx,"graphId":ga,"farm":_vm.farm,"duration":_vm.dur}})],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }