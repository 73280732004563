<template>
  <div class="growCard">
    <div v-if="dataReady">
      <b-card class="growCard__base">
        <b-tabs v-model="tabIndex" pills fill end>
          <b-tab>
            <template #title>
              <i class="fas fa-wind"></i>
              <p>Air</p>
            </template>
            <div class="row growCard__base__inner">
              <h1>{{ graphId }}</h1>
              <table width="100%">
                <tr v-for="(field, i) in charts['air']" :key="i">
                  <td style="width: 30%">
                    <h3>
                      {{ field.series[0].data.slice(-1)[0].y
                      }}<span style="font-size: small">{{ field.unit }}</span>
                    </h3>
                  </td>
                  <td style="width: 70%">
                    <ApexChart
                      :id="'air_' + i"
                      :options="field.options"
                      :series="field.series"
                    >
                    </ApexChart>
                  </td>
                </tr>
              </table>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="fas fa-water"></i>
              <p>Water</p>
            </template>
            <div class="row growCard__base__inner">
              <h1>{{ graphId }}</h1>
              <table width="100%">
                <tr v-for="(field, j) in charts['water']" :key="j">
                  <td style="width: 30%">
                    <h3>
                      {{ field.series[0].data.slice(-1)[0].y
                      }}<span style="font-size: small">{{ field.unit }}</span>
                    </h3>
                  </td>
                  <td style="width: 70%">
                    <ApexChart
                      :id="'water_' + j"
                      :options="field.options"
                      :series="field.series"
                    >
                    </ApexChart>
                  </td>
                </tr>
              </table>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <i class="fas fa-lightbulb"></i>
              <p>Lights</p>
            </template>
            <div class="row growCard__base__inner">
              <h1>{{ graphId }}</h1>
              <table width="100%">
                <tr v-for="(field, k) in charts['light']" :key="k">
                  <td style="width: 30%">
                    <h3>
                      {{ field.series[0].data.slice(-1)[0].y
                      }}<span style="font-size: small">{{ field.unit }}</span>
                    </h3>
                  </td>
                  <td style="width: 70%">
                    <ApexChart
                      :id="'light_' + k"
                      :options="field.options"
                      :series="field.series"
                    >
                    </ApexChart>
                  </td>
                </tr>
              </table>
            </div>
          </b-tab>
        </b-tabs>
        <b-card-footer class="growCard__data-timestamp">
          {{ dataTS | formatTime }}
        </b-card-footer>
      </b-card>
    </div>
    <GrowAreaCardSkeleton v-else/>
  </div>
</template>
<script>
import axios from "axios";
import moment from 'moment';
import GrowAreaCardSkeleton from './growarea/GrowAreaCardSkeleton.vue';

export default {
  props: ["message", "graphId", "farm", "duration"],
  data: () => ({
    charts: {},
    dataReady: false,
    tabIndex: 0,
    dur: 6
  }),
  components : {
    GrowAreaCardSkeleton
  },
  filters: {
    formatTime: function (value) {
      if (value) {
        return moment(value, "YYYY-MM-DD").format("DD-MM-YYYY HH:mm");
      }
    }
  },
  mounted() {
    this.tabIndex = this.message;
    this.dur = this.duration;
    this.displayChart();
  },
  watch: {
    message: function (newValue) {
      this.tabIndex = newValue;
      console.log(this.tabIndex);
    },
    duration: function(newValue){
      this.dur = newValue;
      console.log(this.dur);
      this.dataReady = false;
      this.charts = {};
      this.displayChart();
    }
  },
  methods: {
    displayChart() {
      axios
        .get(`/${this.farm}/sensordata`, {
          params: { growarea: this.graphId, dur: this.dur },
        })
        .then((response) => {
          this.dataTS = new Date();
          for (const m in response.data.sensors) {
            this.charts[m] = this.charts[m] || {};
            for (const f in response.data.sensors[m]) {
              const field = response.data.sensors[m][f].field;
              const time = response.data.sensors[m][f].time;
              const values = response.data.sensors[m][f].values;
              const unit = response.data.sensors[m][f].unit;
              var data = [];
              for (let i = 0; i < values.length; i++) {
                data.push({ x: time[i], y: values[i] });
              }
              this.charts[m][field] = {
                unit: unit,
                series: [{ name: field, data: data }],
                options: {
                  chart: {
                    type: "line",
                    width: 200,
                    height: 70,
                    sparkline: { enabled: true },
                  },
                  stroke: { curve: "smooth" },
                  colors: ["#dab626"],
                  xaxis: { type: "datetime" },
                  tooltip: {
                    enabled: true,
                    x: {
                      show: true,
                      format: 'dd MMM HH:mm'
                    }
                  }
                },
              };
            }
          }
          this.dataReady = true;
        });
    }
  },
};
</script>
<style >
.growCard__data-timestamp {
  margin-top: 7px;
  padding: 0;
  text-align: right;
  bottom: 0;
  background: white;
}
.growCard__base__inner {
  display: block;
  margin: 0px 0px 20px 0px;
}
.growCard__base {
  width: 400px;
  text-align: left;
  padding: 0px 20px;
  border-radius: 10px;
}
.growCard__base * {
  border-radius: 10px;
}
/* .card-text {
  text-align: left;
} */
.growCard .nav-pills .nav-link.active,
.growCard .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #abaeb0;
  border-radius: 1.25rem !important;
}
.growCard .nav-pills a {
  color: rgb(185, 183, 183);
}
.growCard p {
  margin: 0px;
  padding: 0px;
}
.growCard td {
  vertical-align: bottom;
}
</style>
