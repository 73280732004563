<template>
	<div id="growAreaCardSkelly">
		<div class="pulse"></div>
	</div>
</template>

<script>
export default {
	name : "GrowAreaCardSkeleton"
}
</script>

<style>
#growAreaCardSkelly  {
	padding: 50px 100px;
}

#growAreaCardSkelly div {
	height: 20px;
	width: 100%;
	margin-bottom: 290px;
	background-color: rgb(211, 211, 211);

}
</style>